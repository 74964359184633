import React, { useEffect, useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledTooltip,
    Input,
    FormFeedback,
    Label,
    Form,
    Dropdown,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    ModalFooter,
} from "reactstrap";

import Swal from 'sweetalert2';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
    getCustomers as onGetCustomers,
    addNewCustomer as onAddNewCustomer,
    updateCustomer as onUpdateCustomer,
    deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../components/Common/TableContainer';
import toastr from "toastr";


// Column
import {
    UserName,
    Email,
    TotalCredits,
    RemainingCredits,
    Rating,
    WalletBalances,
    JoiningDate,
    Discount,
    Campaign,
    Partner,
    Password,
    BacklinkCredits,
    DateJoined,
} from './UserCol';
import { changeUserStatus, deleteUser, getAllUsers, updateUserDiscount } from "services/main.service";

const AllUsers = props => {

    //meta title
    document.title = "Users | Skote - React Admin & Dashboard Template";

    const dispatch = useDispatch();

    const [allUserData, setAllUserData] = useState([])

    useEffect(() => {
        getAllUsersData()
    }, [])

    const getAllUsersData = () => {
        getAllUsers().then((res) => {
            console.log(res.data, 'res');
            setAllUserData(res.data)
        }).catch((err) => {
            console.log(err, 'error');
        }).finally(() => {
            // setIsLoading(false);
        });
    }

    const { customers } = useSelector(state => ({
        customers: state.ecommerce.customers,
    }));

    const [modal, setModal] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [modal_standard, setmodal_standard] = useState(false);
    const [userData, setUserData] = useState();

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: (customer && customer.username) || '',
            phone: (customer && customer.phone) || '',
            email: (customer && customer.email) || '',
            address: (customer && customer.address) || '',
            rating: (customer && customer.rating) || '',
            walletBalance: (customer && customer.walletBalance) || '',
            joiningDate: (customer && customer.joiningDate) || '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your Name"),
            phone: Yup.string().required("Please Enter Your Phone"),
            email: Yup.string().required("Please Enter Your Email"),
            address: Yup.string().required("Please Enter Your Address"),
            rating: Yup.string().required("Please Enter Your Rating"),
            walletBalance: Yup.string().required("Please Enter Your Wallet Balance"),
            joiningDate: Yup.string().required("Please Enter Your Joining Date"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateCustomer = {
                    id: customer ? customer.id : 0,
                    username: values.username,
                    phone: values.phone,
                    email: values.email,
                    address: values.address,
                    rating: values.rating,
                    walletBalance: values.walletBalance,
                    joiningDate: values.joiningDate,
                };
                // update customer
                dispatch(onUpdateCustomer(updateCustomer));
                validation.resetForm();
            } else {
                const newCustomer = {
                    id: Math.floor(Math.random() * (30 - 20)) + 20,
                    username: values["username"],
                    phone: values["phone"],
                    email: values["email"],
                    address: values["address"],
                    rating: values["rating"],
                    walletBalance: values["walletBalance"],
                    joiningDate: values["joiningDate"],
                };
                // save new customer
                dispatch(onAddNewCustomer(newCustomer));
                validation.resetForm();
            }
            toggle();
        },
    });

    const changeStatus = (values) => {
        console.log(values, "values");
        const user_data = {
            email: values.email,
            status: values.is_active
        }
        changeUserStatus(user_data).then((res) => {
            console.log(res.data, 'res');
            toastr.success(res.data.message)
            getAllUsersData();
        }).catch((err) => {
            console.log(err, 'error');
        }).finally(() => {
            // setIsLoading(false);
        });
    }

    const handleCustomerClick = (val) => {
        console.log(val, 'val');
        setmodal_standard(true)
        setUserData({
            id: val.id,
            email: val.email,
            credits: val.credits,
            bl_credits: val.backlink_credits,
            discount: val.discount,
            coupon: val.coupon,
            partner: val.partner,
            campaign: val.campaign
        })
    }

    const handleDiscountUpdate = () => {
        console.log(userData, 'data');
        updateUserDiscount(userData).then((res) => {
            console.log(res.data, 'res');
            toastr.success(res.data.message)
            getAllUsersData();
            setmodal_standard(false)
        }).catch((err) => {
            console.log(err, 'error');
        }).finally(() => {
            // setIsLoading(false);
        });
    }


    // Customber Column
    const columns = useMemo(
        () => [
            {
                Header: 'Username',
                accessor: 'username',
                filterable: true,
                Cell: (cellProps) => {
                    return <UserName {...cellProps} />;
                }
            },
            {
                Header: 'Password',
                accessor: 'password',
                filterable: true,
                Cell: (cellProps) => {
                    return <Password {...cellProps} />;
                    ;
                }
            },
            // {
            //     Header: 'Email',
            //     accessor: 'email',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <Email {...cellProps} />;
            //         ;
            //     }
            // },
            {
                Header: 'Created Date',
                accessor: 'date_joined',
                filterable: true,
                Cell: (cellProps) => {
                    return <DateJoined {...cellProps} />;
                    ;
                }
            },
            {
                Header: 'Total Credits',
                accessor: 'total_credits',
                filterable: true,
                Cell: (cellProps) => {
                    return <TotalCredits {...cellProps} />;
                }
            },
            {
                Header: 'Remaining Credits',
                accessor: 'credits',
                filterable: true,
                Cell: (cellProps) => {
                    return <RemainingCredits {...cellProps} />;
                }
            },
            {
                Header: 'Backlink Credits',
                accessor: 'backlink_credits',
                filterable: true,
                Cell: (cellProps) => {
                    return <BacklinkCredits {...cellProps} />;
                }
            },
            // {
            //     Header: 'Discount',
            //     accessor: 'discount',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <Discount {...cellProps} />;
            //     }
            // },
            {
                Header: 'Partner',
                accessor: 'partner',
                filterable: true,
                Cell: (cellProps) => {
                    return <Partner {...cellProps} />;
                }
            },
            {
                Header: 'Campaign',
                accessor: 'campaign',
                filterable: true,
                Cell: (cellProps) => {
                    return <Campaign {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                Cell: (cellProps) => {
                    return <div className="form-check form-switch form-switch-md">

                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="stop_check_indexed"
                            defaultChecked={(cellProps.value == 1)}
                            onClick={() => changeStatus(cellProps.row.values)}
                        // onClick={(e) => { setProjectData({ ...projectData, stop_check_indexed: !toggleStopCheckIndexed }), setToggleStopCheckIndexed(!toggleStopCheckIndexed) }}
                        />
                    </div>;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                            <Link
                                to="#"
                                className="btn btn-md btn-soft-info"
                                onClick={() => {
                                    const customerData = cellProps.row.original;
                                    handleCustomerClick(customerData);
                                }}
                            >
                                <i className="bx bx-reply" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            </li>
                            <li>
                            <Link
                                to="#"
                                className="btn btn-md btn-soft-danger"
                                onClick={() => {
                                    const customerData = cellProps.row.original;
                                    handleDeleteClick(customerData);
                                }}
                            >
                                <i className="bx bx-wrench" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                            </li>
                        </ul>
                        // <UncontrolledDropdown>
                        //     <DropdownToggle tag="a" href="#" className="card-drop">
                        //         <i className="mdi mdi-dots-horizontal font-size-18"></i>
                        //     </DropdownToggle>

                        //     <DropdownMenu className="dropdown-menu-end">
                        //         <DropdownItem href="#"
                        //             onClick={() => {
                        //                 const customerData = cellProps.row.original;
                        //                 handleCustomerClick(customerData);
                        //             }
                        //             }
                        //         >
                        //             <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                        //             Edit
                        //             <UncontrolledTooltip placement="top" target="edittooltip">
                        //                 Edit
                        //             </UncontrolledTooltip>
                        //         </DropdownItem>

                        //         <DropdownItem href="#"
                        //             onClick={() => {
                        //                 const customerData = cellProps.row.original;
                        //                 onClickDelete(customerData);
                        //             }}>
                        //             <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                        //             Delete
                        //             <UncontrolledTooltip placement="top" target="deletetooltip">
                        //                 Delete
                        //             </UncontrolledTooltip>
                        //         </DropdownItem>
                        //     </DropdownMenu>
                        // </UncontrolledDropdown>
                    );
                }
            },
        ],
        []
    );

    const toggle = () => {
        if (modal) {
            setModal(false);
            setCustomer(null);
        } else {
            setModal(true);
        }
    };


    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleDeleteClick = (val) => {
        console.log(val, 'val');
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser(val.id).then((res) => {
                    console.log(res, "response");

                    Swal.fire({
                        title: "Deleted!",
                        text: "User has been deleted.",
                        icon: "success"
                    });
                    getAllUsersData();
                }).catch((error) => {
                    console.log({ error });
                });
            }
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Users" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={allUserData}
                                        isGlobalFilter={true}
                                        isAddCustList={false}
                                        customPageSize={10}
                                        className="custom-header-css"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        isOpen={modal_standard}
                        toggle={() => {
                            tog_standard();
                        }}
                    >
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            handleDiscountUpdate(e);
                        }}>
                            <ModalHeader>Update Discount</ModalHeader>
                            <ModalBody>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        User Email
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="user_email"
                                        name="user_email"
                                        value={userData?.email}
                                        readOnly
                                    />
                                </div>
                                {/* <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Coupon
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="coupon"
                                        name="coupon"
                                        value={userData?.coupon}
                                        readOnly
                                    />
                                </div> */}
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Partner
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="partner"
                                        name="partner"
                                        value={userData?.partner}
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Campaign
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="campaign"
                                        name="campaign"
                                        value={userData?.campaign}
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="autoSizingInputGroup">Credits</Label>
                                    <InputGroup>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="autoSizingInputGroup"
                                            min={0}
                                            onChange={(e) => setUserData({ ...userData, credits: e.target.value })}
                                            value={userData?.credits}
                                            required />
                                    </InputGroup>
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="autoSizingInputGroup">Backlink Credits</Label>
                                    <InputGroup>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="autoSizingInputGroup"
                                            min={0}
                                            onChange={(e) => setUserData({ ...userData, bl_credits: e.target.value })}
                                            value={userData?.bl_credits}
                                            required />
                                    </InputGroup>
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="autoSizingInputGroup">Discount</Label>
                                    <InputGroup>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="autoSizingInputGroup"
                                            min={0}
                                            max={100}
                                            onChange={(e) => setUserData({ ...userData, discount: e.target.value })}
                                            value={userData?.discount}
                                            required />
                                        <div className="input-group-text">%</div>
                                    </InputGroup>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button
                                    type="submit"
                                    className="btn btn-lg btn-primary "
                                >
                                    Save
                                </button>
                            </ModalFooter>
                        </Form>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );
};

AllUsers.propTypes = {
    customers: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onAddNewCustomer: PropTypes.func,
    onDeleteCustomer: PropTypes.func,
    onUpdateCustomer: PropTypes.func,
};

export default AllUsers;
