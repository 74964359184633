import axios from "axios";

console.log(process.env.REACT_APP_BACKEND_ENDPOINT, 'baseUrl');

const getHeaders = () => {
    let userData = localStorage.getItem('authUser');
    if (userData) {
       let user = JSON.parse(userData);
       console.log({user});
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user?.token}`
        }
    }
    return {
        'Content-Type': 'application/json'
    }
}

export const loginUserService = (userData) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/login`, userData);
}

export const getAllUsers = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/all/users`, { headers: getHeaders() });
}

export const changeUserStatus = (userData) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/change/user/status`, userData, { headers: getHeaders() });
}

export const updateUserDiscount = (userData) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/update/discount`, userData, { headers: getHeaders() });
}

export const getAllPackages = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/all/user_packages`, { headers: getHeaders() });
}

export const updateUserPackage = (packageData) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/update/package`, packageData, { headers: getHeaders() });
}

export const deleteUser = (user_id) => {
    return axios.delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/delete/user/${user_id}`, { headers: getHeaders() });
}