import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getAllPackages, updateUserPackage } from "services/main.service";
import { Link } from "react-router-dom";
import toastr from "toastr";

const AllPackages = () => {

    //meta title
    document.title = "User Packages | Skote - React Admin & Dashboard Template";

    useEffect(() => {
        getAllUserPackages();
    }, [])
    const [creditPackages, setCrediPackages] = useState([]);
    const [packageDets, setPackageDets] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const getAllUserPackages = () => {
        getAllPackages().then((res) => {
            console.log(res.data, 'res');
            setCrediPackages(res.data)
            setIsLoading(false)
        }).catch((err) => {
            console.log(err, 'error');
        }).finally(() => {
            setIsLoading(false);
        });
    }
    const [modal_standard, setmodal_standard] = useState(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleOpenEditModal = (val) => {
        console.log(val);
        setPackageDets({
            id: val.id,
            package_name: val.package_name,
            price: val.price,
            credits: val.credits,
            cost_per_credit: val.cost_per_credit,
            url_limit_project: val.url_limit_project,
            url_limit_domain: val.url_limit_domain,
            backlink_credits: val.backlink_credits
        })
        setmodal_standard(true)
    }

    const costCalculateCredit = (credit) => {
        const newVal = packageDets.price / credit;
        setPackageDets({
            ...packageDets,
            credits: credit,
            cost_per_credit: newVal
        });
    }

    const costCalculatePrice = (price) => {
        const newVal = price / packageDets.credits;
        setPackageDets({
            ...packageDets,
            price: price,
            cost_per_credit: newVal
        });
    }

    const handleUpdate = () => {
        console.log(packageDets, "packageDets");
        updateUserPackage(packageDets).then((res) => {
            console.log(res.data, 'res');
            toastr.success(res.data.message)
            getAllUserPackages();
            setmodal_standard(false)
        }).catch((err) => {
            console.log(err, 'error');
        }).finally(() => {
            // setIsLoading(false);
        });
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Utility" breadcrumbItem="Pricing" /> */}

                    <Modal
                        isOpen={modal_standard}
                        toggle={() => {
                            tog_standard();
                        }}
                    >
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            handleUpdate(e);
                        }}>
                            <ModalHeader>Update Package</ModalHeader>
                            <ModalBody>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Package Name
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="package_name"
                                        name="package_name"
                                        value={packageDets?.package_name}
                                        onChange={(e) => setPackageDets({ ...packageDets, package_name: e.target.value })}
                                        required
                                    />
                                </div>
                                <Row>
                                    <Label htmlFor="autoSizingInputGroup">Price</Label>
                                    <Col lg={7}>
                                        <div className="mb-3">
                                            <InputGroup>
                                                <div className="input-group-text">$</div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="autoSizingInputGroup"
                                                    min={0}
                                                    onChange={(e) => costCalculatePrice(e.target.value)}
                                                    value={packageDets?.price}
                                                    required />
                                                <div className="input-group-text">.00</div>
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col lg={5}>
                                        <div className="mb-3">
                                            {/* <Label htmlFor="autoSizingInputGroup">Credits</Label> */}
                                            <InputGroup>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="autoSizingInputGroup"
                                                    min={0}
                                                    onChange={(e) => costCalculateCredit(e.target.value)}
                                                    value={packageDets?.credits}
                                                    required />
                                                <div className="input-group-text">Credits</div>
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={9}>
                                        <div className="mb-3">
                                            {/* <Label htmlFor="autoSizingInputGroup">Credits</Label> */}
                                            <InputGroup>
                                                <div className="input-group-text">$</div>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="autoSizingInputGroup"
                                                    min={0}
                                                    onChange={(e) => setPackageDets({ ...packageDets, cost_per_credit: e.target.value })}
                                                    value={packageDets?.cost_per_credit}
                                                    readOnly />
                                                <div className="input-group-text">Cost per Credit</div>
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label htmlFor="autoSizingInputGroup">URL Limitations</Label>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <InputGroup>
                                                <div className="input-group-text">Standard Projects</div>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="autoSizingInputGroup"
                                                    min={0}
                                                    onChange={(e) => setPackageDets({ ...packageDets, url_limit_project: e.target.value })}
                                                    value={packageDets?.url_limit_project}
                                                    required />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <InputGroup>
                                                <div className="input-group-text">Domain Monitoring</div>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="autoSizingInputGroup"
                                                    min={0}
                                                    onChange={(e) => setPackageDets({ ...packageDets, url_limit_domain: e.target.value })}
                                                    value={packageDets?.url_limit_domain}
                                                    required />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label htmlFor="autoSizingInputGroup">Backlink</Label>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <InputGroup>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="autoSizingInputGroup"
                                                    min={0}
                                                    onChange={(e) => setPackageDets({ ...packageDets, backlink_credits: e.target.value })}
                                                    value={packageDets?.backlink_credits}
                                                    required />
                                                <div className="input-group-text">Credits</div>
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>

                            </ModalBody>
                            <ModalFooter>
                                <button
                                    type="submit"
                                    className="btn btn-lg btn-primary "
                                >
                                    Save
                                </button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                    {/* <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="text-center mb-5">
                                <h4>Choose your Pricing plan</h4>
                                <p className="text-muted">
                                    We offer free and paid premium access plans to support ethical access for all webmasters.
                                </p>
                            </div>
                        </Col>
                    </Row> */}

                    {
                        isLoading ? (
                            <Row>
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                            Loading...
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <>
                                <Row>
                                    <Col xl={12}>
                                        <Card>
                                            <CardBody>
                                                <div className="table-responsive">
                                                    <h4 className="card-title">User Packages</h4>
                                                    {/* <p className="card-title-desc">Table cells in <code>&lt;tbody&gt;</code> inherit their alignment from <code>&lt;table&gt;</code> and are aligned to the the top by default. Use the vertical align classes to re-align where needed.</p> */}

                                                    <div className="table-responsive">
                                                        <Table className="align-middle mb-0">

                                                            <thead>
                                                                <tr>
                                                                    <th>Package Name</th>
                                                                    <th>Credits</th>
                                                                    <th>Price</th>
                                                                    <th>Cost per Credit</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {creditPackages.map((package1, index) => (
                                                                    <tr key={index}>
                                                                        <td>{package1.package_name}</td>
                                                                        <td>{package1.credits}</td>
                                                                        <td>${package1.price}.00</td>
                                                                        <td>${package1.cost_per_credit}</td>
                                                                        <td>
                                                                            <button type="button" onClick={() => handleOpenEditModal(package1)} style={{ "backgroundColor": "#00264c", "color": "#fff" }} className="btn btn-light btn-sm">Edit</button>
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </Table>
                                                    </div>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    {creditPackages.map((package1, index) => (
                                        <Col xl="3" md="6" key={index}>
                                            <Card className="plan-box">
                                                <CardBody className="p-4 text-center">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h5>{package1.package_name}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="py-4">
                                                        <h2>
                                                            <sup>
                                                                <small>$</small>
                                                            </sup>{" "}
                                                            {package1.price}.00
                                                        </h2>
                                                    </div>
                                                    <div className="py-1">
                                                        <h5>{package1.credits}{" "}Credits</h5>
                                                    </div>
                                                    <div className="py-1">
                                                        <h6>${package1.cost_per_credit}{" "}per check</h6>
                                                    </div>
                                                    <div className="text-center plan-btn">
                                                        <Button onClick={() => handleOpenEditModal(package1)} style={{ "backgroundColor": "#00264c", "color": "#fff" }} className="btn btn-primary btn-md waves-effect waves-light ">
                                                            Edit
                                                        </Button>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}

                                </Row> */}
                            </>
                        )}

                </Container>
            </div>
        </React.Fragment>
    )
}

export default AllPackages;
