import React from "react"
import PropTypes from 'prop-types'

const Loading = props => {
  return (
    <>
        <div className="main-loading">
            <div>
                <span className="loader"></span>
                <p>
                    {props?.title ?? "Loading"}
                </p>
            </div>
        </div>
    </>
  )
}

Loading.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Loading