import React from 'react';
import { Link } from 'react-router-dom';

const CustId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const UserName = (cell) => {
    return cell.value ? cell.value : '';
};

const Password = (cell) => {
    return cell.value ? cell.value : '';
};

const DateJoined = (cell) => {
    if (!cell.value) {
        return '';
    }

    const date = new Date(cell.value);
    const formattedDate = date.toISOString().split('T')[0];

    return formattedDate;
};

const Email = (cell) => {
    return cell.value ? cell.value : '';
};

const TotalCredits = (cell) => {
    return cell.value ? cell.value : '0';
};

const RemainingCredits = (cell) => {
    return cell.value ? cell.value : '0';
};

const BacklinkCredits = (cell) => {
    return cell.value ? cell.value : '0';
};

// const Discount = (cell) => {
//     return cell.value ? `${cell.value}%` : '0%';
// };

const Partner = (cell) => {
    return cell.value ? cell.value : '-';
};

const Campaign = (cell) => {
    return cell.value ? cell.value : '-';
};

const Rating = (cell) => {
    return (
        <span className="bg-success font-size-12 badge bg-success"><i className="mdi mdi-star me-1"></i>{cell.value}</span>
    )
};

const WalletBalances = (cell) => {
    return cell.value ? cell.value : '';
};

const JoiningDate = (cell) => {
    return cell.value ? cell.value : '';
};

export {
    CustId,
    UserName,
    Password,
    DateJoined,
    Email,
    TotalCredits,
    RemainingCredits,
    BacklinkCredits,
    // Discount,
    Partner,
    Campaign,
    Rating,
    WalletBalances,
    JoiningDate,
};